export const BASE_URL_ASSETS = "https://5lc-website-assets.pages.dev"

export const VENUE = {
  NAME: "Bóveda Secreta",
  ADDRESS: "San Antonio 705, Santiago. Región Metropolitana",
  MAP_URL: "https://maps.app.goo.gl/W95NsXm12SRUNKfW9",
};

export const infoLanding = {
  hero: {
    title: "Clandestino",
    description:
      "¡Sumérgete en la clandestinidad de 5 Luchas! Embárcate en un viaje liberador que romperá las cadenas del estrés y la monotonía a través de la electrizante pasión de la Lucha Libre.",
    image:
      `${BASE_URL_ASSETS}/home_preview_web_video.jpg`,
    video:
      `${BASE_URL_ASSETS}/home_bg_video.mp4`,
  },
  newsletter: {
    title: "¡Suscríbete a nuestro Newsletter!",
    description:
      "Semanalmente te acercamos las noticias más destacadas del proyecto, los eventos venideros, las carteleras, los descuentos y mucho más.",
    btn: "YA PO'",
  },
  cta: {
    title: "¡Se parte!",
    description: "Y súmate a la comunidad",
    image:
      `${BASE_URL_ASSETS}/sara_cta.jpg`,
  },
};

export const faq = [
  {
    question: "¿Qué debo presentar para ingresar a un evento?",
    answer:
      " El ingreso a nuestros shows se gestiona mediante una lista en el acceso el día del evento. Allí, te solicitarán tu nombre, RUT o el correo electrónico utilizado para comprar las entradas. El ingreso se realiza por orden de llegada y no se permite reservar espacios.",
  },
  {
    question: "¿A que hora comienzan los eventos de 5 Luchas?",
    answer:
      "Salvo casos particulares, los shows comienzan a las 20:00 hrs. El ingreso comienza a las 19:15 hrs.",
  },
  {
    question: "¿Pueden ingresar menores a los shows de 5 Luchas?",
    answer:
      "El ingreso de menores de hasta 15 años de edad a nuestros shows es siempre en compañía de un adulto. El espectáculo está pensado para público adulto, por lo que siempre se recomienda discreción.",
  },
  {
    question: "¿Cómo se quién participará en cada show?",
    answer:
      "La cartelera y los participantes de cada espectáculo se develarán en los días cercanos a la fecha. Esta información siempre quedará sujeta a modificaciones.",
  },
  {
    question: "¿Puedo ingresar comida y bebestibles a la Bóveda?",
    answer:
      "No, disponemos de una variada gama de bebidas y refrigerios para tu deleite, incluyendo opciones vegetarianas. Apoyándonos consumiendo lo que ofrecemos en cada espectáculo, nos ayudas a seguir brindándote la mejor experiencia de Lucha Libre.",
  },
  {
    question: "¿Que pasa si compre mi entrada y no puedo ir?",
    answer:
      "En caso de imprevistos que te impidan asistir, puedes contactarnos a hola@5luchas.cl para solicitar el reembolso de tu entrada. También tienes la opción de venderla a otra persona interesada en asistir al evento. Estos trámites deben realizarse antes de la fecha del show correspondiente.",
  },
];